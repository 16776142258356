@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Noto Sans TC', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

/* antd message 變更 */
.ant-message {
  font-size:23px !important;
  color: #595959 !important;
}