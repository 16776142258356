/* .abcBoardTable .ant-table-tbody > tr > td{
	word-wrap: break-word;
	word-break: break-all;
} */
.abcBoardTable .ant-table-tbody > tr > td:nth-child(12){
	background-color: #e5e7fd;
}
  
/* .abcBoardTable .ant-table-thead > tr > th:last-child{
	background-color: #051aff;
} */
